@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display");

@layer base {
  html {
    font-family: "Plus Jakarta Sans", sans-serif;
  }
}

input {
  -webkit-appearance: none;
  box-shadow: none !important;
}
:-webkit-autofill {
  color: #fff !important;
}

p {
  /* sentence spacing */
  /* leading relaxed */
  line-height: 1.625;
}
